
import BaseFormScope from './BaseFormScope'
import { isValueSet } from '~/utils/Utils'

export default {
  name: 'BaseFormSelect',

  extends: BaseFormScope,

  inject: ['modal'],

  props: {
    items: {
      type: Array,
      required: true,
    },

    itemValue: {
      type: String,
      default: 'value',
    },

    labelInside: {
      type: String,
      default: '',
    },

    itemText: {
      type: String,
      default: 'text',
    },

    native: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isMultiselect: {
      type: Boolean,
      default: false,
    },

    labelMultiselect: {
      type: String,
      default: '',
    },

    isTranslated: {
      type: Boolean,
      default: true,
    },

    isSearch: {
      type: Boolean,
      default: false,
    },

    defaultScrollValue: {
      type: Number,
      default: null,
    },

    hideErrors: {
      type: Boolean,
      default: false,
    },

    minListWidth: {
      type: Number,
      default: 0,
    },

    isBaseFlag: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isOpen: false,
      searchText: '',
      searchPlaceholder: '',
      modalSelectId: null,
    }
  },

  computed: {
    getVisibleValue () {
      const count = this.itemsValue.reduce((acc, item) => {
        if (item.checked) {
          acc++
        }
        return acc
      }, 0)

      if (this.isMultiselect) {
        const hasSelectOneValue = count === 1 ? this.getFirstMultiselectName[0].text : `${this.labelMultiselect} (${count})`
        const label = count ? hasSelectOneValue : this.labelInside

        return label
      }

      const option = this.items.find(option => option[this.itemValue] === this.value)

      return option ? option[this.itemText] : ''
    },

    getFirstMultiselectName () {
      return this.itemsValue.filter(item => item.value === this.value[0])
    },

    itemsValue () {
      return this.items.map((item) => {
        item.checked = (this.isMultiselect ? this.value : [this.value]).includes(item.value)

        return item
      })
    },

    filtredItemsValue () {
      return this.isSearch && (this.searchText !== '' || this.searchText !== this.labelInside)
        ? this.items.filter(item => item?.text.toString().toLowerCase().includes(this.searchText.toLowerCase()))
        : this.items
    },

    getModalData () {
      return this.$store.state.modal.modalData
    },

  },

  watch: {
    async getModalData (data) {
      const isValidData = !!data &&
        this.modalSelectId === data.id &&
        !!data.type &&
        data.value !== null

      if (!isValidData) { return }

      const actionMap = {
        select: () => this.onValueChange(data.value),
        'check-all': () => this.checkAll(data.value),
      }

      if (actionMap[data.type]) {
        await actionMap[data.type]()
      }
    },

    isOpen (value) {
      this.$nextTick(async () => {
        if (!value) {
          this.$emit('validate')
        }

        if (!this.$css.breakpoints.mdAndUp && value) {
          try {
            const BaseFormSelectModal = await import('~/components/base/form/select/BaseFormSelectModal.vue')
            const modalInstance = await this.modal()

            this.modalSelectId = this.$id('modal')

            modalInstance.openModal(BaseFormSelectModal.default, {
              labelInside: this.labelInside,
              isTranslated: this.isTranslated,
              isSearch: this.isSearch,
              value: this.value,
              items: this.items,
              itemValue: this.itemValue,
              itemText: this.itemText,
              isMultiselect: this.isMultiselect,
              isOpen: this.isOpen,
              defaultScrollValue: this.defaultScrollValue,
              minListWidth: this.minListWidth,
              modalSelectId: this.modalSelectId,
              isBaseFlag: this.isBaseFlag,
            })
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
          }
        }
      })
    },

    value: {
      handler () {
        this.searchPlaceholder = this.getVisibleValue || this.labelInside
      },

      immediate: true,
    },

    items: {
      handler () {
        if (isValueSet(this.value) && !this.isMultiselect && !this.items?.find(({ value }) => value === this.value)) {
          this.$emit('input', null)
        }
      },

      immediate: true,
    },
  },

  beforeDestroy () {
    this.$store.dispatch('modal/setModalData', null)
  },

  methods: {
    checkAll (value) {
      const response = this.itemsValue.reduce((acc, item) => {
        item.checked = value
        if (item.checked) {
          acc.push(item.value)
        }
        return acc
      }, [])

      this.$emit('input', response)

      this.searchPlaceholder = this.setInputLabel()
    },

    onClose () {
      this.isOpen = false
      this.searchPlaceholder = this.setInputLabel()
    },

    onValueChange (value) {
      if (this.isMultiselect) {
        const response = this.itemsValue.reduce((acc, item) => {
          if (value === item.value) {
            item.checked = !item.checked
          }

          if (item.checked) {
            acc.push(item.value)
          }
          return acc
        }, [])

        this.$emit('input', response)

        this.searchPlaceholder = this.setInputLabel()
      } else {
        this.isOpen = false

        this.$emit('input', value)

        this.searchText = ''

        const val = this.itemsValue.find(el => el.value === value)

        this.searchPlaceholder = val ? val.text : null
      }
    },

    searchFocus () {
      if (this.disabled) {
        this.isOpen = false
        return
      }

      this.searchText = ''
      this.searchPlaceholder = ''

      this.isOpen = true
      this.$nextTick(() => {
        if (this.isSearch) {
          this.$css.breakpoints.mdAndUp
            ? this.$refs.search?.focus() || this.$refs.searchBtn?.focus()
            : this.$refs.modalSearch?.focus()
        }
      })
    },

    setInputLabel () {
      this.searchText = ''
      const count = this.itemsValue.reduce((n, e) => e.checked === true ? n + 1 : n, 0)

      if (count === 0) {
        return this.labelInside
      }

      return count > 1 ? `${this.labelMultiselect} (${count})` : this.itemsValue.filter(item => item.checked)[0].text
    },

    onSelectChange (event) {
      const values = Object.values(event.target.selectedOptions).reduce((acc, item) => {
        acc.push(item.value)
        return acc
      }, [])

      this.onValueChange(values)
    },

    onFieldClick () {
      this.isOpen = !this.isOpen

      if (this.isOpen && this.isSearch) {
        this.searchFocus()
      } else {
        this.searchPlaceholder = this.setInputLabel()
      }
    },

    onKeyUp () {
      if (this.isOpen) {
        const index = this.items.findIndex(item => item[this.itemValue] === this.value)
        const prev = this.items[index - 1]

        if (prev) {
          this.$emit('input', prev[this.itemValue])
        }
      }
    },

    onKeyDown () {
      if (this.isOpen) {
        const index = this.items.findIndex(item => item[this.itemValue] === this.value)
        const next = this.items[index + 1]

        if (next) {
          this.$emit('input', next[this.itemValue])
        }
      }
    },
  },
}
