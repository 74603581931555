
import uniqIdMixin from '~/mixins/uniq-id'

export default {
  name: 'BaseFormSelectList',
  mixins: [
    uniqIdMixin,
  ],

  props: {
    items: {
      type: Array,
      required: true,
    },

    value: {
      type: [String, Boolean, Number, Array],
      default: '',
    },

    itemValue: {
      type: String,
      required: true,
    },

    itemText: {
      type: String,
      required: true,
    },

    isMultiselect: {
      type: Boolean,
      default: false,
    },

    isOpen: {
      type: Boolean,
      default: false,
    },

    isTranslated: {
      type: Boolean,
      default: false,
    },

    isSearch: {
      type: Boolean,
      default: false,
    },

    filtredItemsValue: {
      type: Array,
      required: true,
    },

    defaultScrollValue: {
      type: Number,
      default: null,
    },

    minListWidth: {
      type: Number,
      default: 0,
    },

    isBaseFlag: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    itemsInSelect () {
      return this.isSearch ? this.filtredItemsValue : this.itemsValue
    },

    isCheckAll () {
      return this.itemsValue.every(item => item.checked === true)
    },

    itemsValue () {
      return this.items.map((item) => {
        item.checked = (this.isMultiselect ? this.value : [this.value]).includes(item.value)

        return item
      })
    },
  },

  watch: {
    isOpen: {
      handler () {
        this.$nextTick(() => {
          const list = this.$refs.listbox
          const option = list.querySelector('[aria-selected="true"]') || list.querySelector('[aria-default="true"]')

          if (option) {
            list.scrollTop = option.offsetTop - option.offsetHeight * 2
          }
        })
      },

      immediate: true,
    },
  },
}
